import React, { Fragment, useContext, useEffect, useState } from 'react';
import { AssessmentContext } from '../context/AssessmentContext';
import { CountryContext } from '../context/CountryContext';
import { fetchFirstExistingVisaNode, fetchNextExistingVisaNode, fetchExistingVisaIndicative } from '../services/ExistingVisaService';
import EXISTING_VISA_STEP_INDEX from '../consts/EXISTING_VISA_STEP_INDEX';
import EXISTING_VISA_TYPES from '../consts/EXISTING_VISA_TYPES';
import ErrorOutlineOutlined from '@material-ui/icons/ErrorOutlineOutlined';
import Loading from './Loading';
import Select, { components } from 'react-select';
import IconCheckmark from './IconCheckmark';

const ExistingVisaNodes = () => {
  // Context variables
  const [assessmentState, assessmentDispatch] = useContext(AssessmentContext);
  const [countryState] = useContext(CountryContext);

  // State variables
  const [isLoading, setIsLoading] = useState(false);
  const [node, setNode] = useState(null);
  const [previouslyAnsweredNodes, setPreviouslyAnsweredNodes] = useState([]);
  const [selectedEdge, setSelectedEdge] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    fetchFirstNode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  

  const fetchFirstNode = async () => {
    const firstNodeResponse = await fetchFirstExistingVisaNode(countryState.selectedDestinationCountry.id, countryState.selectedDestinationCountry.regions[0].id, assessmentState.selectedExistingVisa.value.id);
    const indicativeTimeResponse = await fetchExistingVisaIndicative(countryState.selectedDestinationCountry.id, 
      countryState.selectedDestinationCountry.regions[0].id,
      firstNodeResponse.id)
    const indicativeTime = indicativeTimeResponse.indicativeTime;
    
    //list of Uk special Visa node ID
    const specialUKvisas = [57,93];
    // E3, H1B1, TN (Visas which have no nodes, straight to generic new assessment screen)
    if (firstNodeResponse.length === 0) {
      assessmentDispatch({
        type: 'SET_EXISTING_VISA_RESULT',
        existingVisaResult: {
          title: 'New Permit or Transfer of Permit May Be Required',
          icon: <ErrorOutlineOutlined className="gw__warning-icon" />,
          subtext: 
            <span>
              Based on the information provided a new permit or a transfer of the current permit may be required. Click next to continue the assessment.
            </span>,
          isContinuingToAssessment: true,
          indicativeTime: indicativeTime,
        },
        currentExistingVisaIndex: EXISTING_VISA_STEP_INDEX.result,
      });
    } else if(specialUKvisas.includes(firstNodeResponse.id)) {
      assessmentDispatch({
        type: 'SET_EXISTING_VISA_RESULT',
        existingVisaResult: {
          title: firstNodeResponse.title,
          icon: <ErrorOutlineOutlined className="gw__warning-icon" />,
          subtext: <span dangerouslySetInnerHTML={{ __html: firstNodeResponse.text }}></span>,
          isContinuingToAssessment: true,
          indicativeTime: indicativeTime,
        },
        currentExistingVisaIndex: EXISTING_VISA_STEP_INDEX.result,
      });
    }    else if(firstNodeResponse.type === EXISTING_VISA_TYPES.endNode) { // J1, O1, Other (Visas which have only one node)
      assessmentDispatch({
        type: 'SET_EXISTING_VISA_RESULT',
        existingVisaResult: {
          title: firstNodeResponse.title,
          icon: <ErrorOutlineOutlined className="gw__warning-icon" />,
          subtext: <span dangerouslySetInnerHTML={{ __html: firstNodeResponse.text }}></span>,
          isContinuingToAssessment: false,
          indicativeTime: indicativeTime,
        },
        currentExistingVisaIndex: EXISTING_VISA_STEP_INDEX.result,
      });
    } else { // Regular existing visa flows (EAD, F1, H1B, L1)
      let nodeDropdownOptionsTemp = [];
    
      for (const edge of firstNodeResponse.childEdges) {
        nodeDropdownOptionsTemp.push({
          label: edge.text,
          value: edge,
        });
      }

      firstNodeResponse.dropdownOptions = nodeDropdownOptionsTemp;
      setNode(firstNodeResponse);
      setIsLoading(false);
    }
  };

  const fetchNextNode = async () => {
    setIsLoading(true);
    let previouslyAnsweredNodesTemp = previouslyAnsweredNodes;
    previouslyAnsweredNodesTemp.push(node);
    setPreviouslyAnsweredNodes(Object.assign([], previouslyAnsweredNodesTemp));
    const nextNodeResponse = await fetchNextExistingVisaNode(countryState.selectedDestinationCountry.id, countryState.selectedDestinationCountry.regions[0].id, assessmentState.selectedExistingVisa.value.id, selectedEdge.value.id);

    setSelectedEdge(null); // clear the dropdown for the next node

    if (nextNodeResponse.type === EXISTING_VISA_TYPES.endNode) {
      assessmentDispatch({
        type: 'SET_EXISTING_VISA_RESULT',
        existingVisaResult: {
          title: nextNodeResponse.title,
          icon: <ErrorOutlineOutlined className="gw__warning-icon" />,
          subtext: <span dangerouslySetInnerHTML={{ __html: nextNodeResponse.text }}></span>,
          isContinuingToAssessment: false,
        },
        currentExistingVisaIndex: EXISTING_VISA_STEP_INDEX.result,
      });
    } else if (nextNodeResponse.type === EXISTING_VISA_TYPES.isEligibleNode) {
      assessmentDispatch({
        type: 'SET_EXISTING_VISA_RESULT',
        existingVisaResult: {
          title: nextNodeResponse.title,
          icon: <div className="gw__results-success"><IconCheckmark /></div>,
          subtext: <span dangerouslySetInnerHTML={{ __html: nextNodeResponse.text }}></span>,
          isContinuingToAssessment: false,
        },
        currentExistingVisaIndex: EXISTING_VISA_STEP_INDEX.result,
      });
    } else if (nextNodeResponse.type === EXISTING_VISA_TYPES.continueToAssessmentNode) {
      assessmentDispatch({
        type: 'SET_EXISTING_VISA_RESULT',
        existingVisaResult: {
          title: 'Further assessment required',
          icon: <div className="gw__warning-icon-yellow"><i className="icon-error_special" /></div>,
          subtext: <span dangerouslySetInnerHTML={{ __html: nextNodeResponse.text }}></span>,
          isContinuingToAssessment: true,
        },
        currentExistingVisaIndex: EXISTING_VISA_STEP_INDEX.result,
      });
    } else {
      let nodeDropdownOptionsTemp = [];
    
      for (const edge of nextNodeResponse.childEdges) {
        nodeDropdownOptionsTemp.push({
          label: edge.text,
          value: edge,
        });
      }

      nextNodeResponse.dropdownOptions = nodeDropdownOptionsTemp;

      setNode(Object.assign({}, nextNodeResponse));
      setIsLoading(false);
    }
  };

  const fetchPreviousNode = () => {
    setIsLoading(true);
    let previouslyAnsweredNodesTemp = previouslyAnsweredNodes;
    let previousNode = previouslyAnsweredNodesTemp.pop();
    setPreviouslyAnsweredNodes(Object.assign([], previouslyAnsweredNodesTemp));
    setNode(previousNode);
    setIsLoading(false);
  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <i className="icon-simple_arrow_down_special gw__requirement-select-icon" />
      </components.DropdownIndicator>
    );
  };

  return (
    <div className="row">
      {
        isLoading || !node
        ? <Loading />
        : <Fragment>
            <div className="col-12 d-flex justify-content-between align-items-center mb-3">
              <span className="gw__existing-visa-section-title">EXISTING VISA</span>
            </div>

            <div className="col-12">
              <p className="gw__existing-visa-question">
                {node.text}
              </p>
            </div>

            <div className="col-12">
              <p className="gw__existing-visa-subtext">
                {node.subtext}
              </p>
            </div>

            <div className="col-12">
              <Select
                className="gw__requirement-react-select-container"
                classNamePrefix="gw__requirement-react-select"
                components={{DropdownIndicator}}
                options={node.dropdownOptions}
                onChange={event => setSelectedEdge(event)}
                value={selectedEdge}
              />
            </div>

            {
              previouslyAnsweredNodes.length > 0
              ? <div className="col-12 d-flex justify-content-between mt-5">
                  <button className="btn btn-secondary" onClick={fetchPreviousNode}>BACK</button>
                  <button className="btn btn-primary" onClick={fetchNextNode} disabled={!selectedEdge}>NEXT</button>
                </div>
              : <div className="col-12 d-flex justify-content-end mt-5">
                  <button className="btn btn-primary" onClick={fetchNextNode} disabled={!selectedEdge}>NEXT</button>
                </div>
            }
          </Fragment>
      }
    </div>
  )
};

export default ExistingVisaNodes;
